<template>
  <div class="m_chart">
    <div class="main" id="container"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  data() {
    return {
      data: [
        { type: '分类一', value: 27 },
        { type: '分类二', value: 25 },
        { type: '分类三', value: 18 },
        { type: '分类四', value: 15 },
        { type: '分类五', value: 10 },
        { type: '其他', value: 5 }
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      var myChart = echarts.init(document.getElementById('container'))
      // 绘制图表
      myChart.setOption({
        title: {
          text: '网点状态',
          textStyle: {
            color: 'rgb(255,255,255)'
          },
          padding: [10, 0, 0, 30]
        },
        textStyle: {
          color: 'rgb(255,255,255)'
        },
        backgroundColor: 'rgb(0,0,0,0.0)',
        tooltip: {},
        xAxis: {
          data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
        },
        yAxis: {},
        series: [
          {
            name: '销量',
            type: 'bar',
            data: [5, 20, 36, 10, 10, 20]
          }
        ]
      })
    }
  }
}
</script>

<style lang="less" scoped>
.m_chart {
  position: absolute;
  left: 10px;
  top: 10px;
  height: 300px;
  width: 300px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
  .main {
    height: 100%;
    width: 100%;
  }
}
</style>
